/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        "./assets/src/js/**/*.js",
        "./templates/**/*.html.twig",
        "./src/Form/Type/**/*.php",
        "./src/Controller/**/*.php",
        "./vendor/acc-digital/**/*.html.twig",
    ],
    safelist: process.env.NODE_ENV === "development" ? [{ pattern: /.*/ }] : [],
    darkMode: 'selector',
    theme: {
        screens: {
            xs: "380px",
            sm: "480px",
            md: "768px",
            lg: "1024px",
            xl: "1280px",
            xxl: "1440px",
            xxxl: "1680px",
            max: "1920px",
        },
        container: {
            center: true,
            padding: {
                DEFAULT: "2.125rem",
                md: "3rem",
                xl: "4rem",
                xxxl: "5rem",
            },
            screens: {
                xs: "100%",
                sm: "100%",
                md: "768px",
                lg: "1024px",
                xl: "1280px",
                xxl: "1440px",
                xxxl: "1680px",
                max: "1920px",
            },
        },
        colors: {
            transparent: "transparent",
            current: "currentColor",
            white: "#ffffff",
            black: "#000000",
            gray: {
                500: "#999999",
            },
            admin: {
                yellow: {
                    100: "#f6e58d",
                    500: "#f7b731",
                },
            },
        },
        fontFamily: {
            monument: ["Monument Grotesk Mono", "ui-sans-serif", "system-ui"],
            david: ["David", "ui-sans-serif", "system-ui"],
        },
        fontWeight: {
            regular: 400,
        },
        fontSize: {
            15: ["0.9375rem", { lineHeight: "1.3125rem" }],
            16: ["1rem", { lineHeight: "1.375rem" }],
            17: ["1.0625rem", { lineHeight: "1.4375rem" }],
            18: ["1.125rem", { lineHeight: "1.5625rem" }],
            20: ["1.25rem", { lineHeight: "1.5625rem" }],
            25: ["1.5625rem", { lineHeight: "1.875rem" }],
            30: ["1.875rem", { lineHeight: "2.25rem" }],
            35: ["2.1875rem", { lineHeight: "2.8125rem" }],
        },
        lineHeight: {
            42: "2.625rem",
        },
        extend: {
            padding: {
                4.5: "1.125rem",
                5.5: "1.375rem",
                6.5: "1.625rem",
                7.5: "1.875rem",
                13.5: "3.375rem",
            },
            inset: {
                8.5: "2.125rem",
                24: "6rem",
            },
            margin: {
                4.5: "1.125rem",
                5.5: "1.375rem",
                6.5: "1.625rem",
                7.5: "1.875rem",
                8.5: "2.125rem",
                18: "4.5rem",
                22: "5.5rem",
                30: "7.5rem",
            },
            height: {
                8.5: "2.125rem",
                18: "4.5rem",
            },
            gap: {
                5.5: "1.375rem",
                13: "3.375rem",
                18: "4.5rem",
            },
            aspectRatio: {
                "2/1": "2 / 1",
                "3/1": "3 / 1",
                "5/2": "5 / 2",
                "5/3": "5 / 3",
                "5/4": "5 / 4",
                "5/6": "5 / 6",
                "7/11": "7 / 11",
                "9/16": "9 / 16",
                "11/5": "11 / 5",
                "32/11": "32 / 11",
                "72/109": "72 / 109",
                "109/72": "109 / 72",
            },
            dropShadow: {
                "slider-nav": "0 3px 7px rgba(0, 0, 0, 0.6)",
            },
        },
    },
    plugins: [],
};
